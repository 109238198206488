import React from 'react'
import Frame from '../components/frame'
import '../css/home.scss'
import Body from '../components/body'
import Nav from '../components/nav'
const Home = () => {
  return (
    <div>
      <Nav/>
      <div className="page-container">
        <Frame />
        <Body />
      </div>
    </div>
  )
}

export default Home
