import React, { useRef, useEffect, useState } from 'react'
import Walk1 from '../assets/sam_sprites/walk/walk0001.png'
import Walk2 from '../assets/sam_sprites/walk/walk0002.png'
import Walk3 from '../assets/sam_sprites/walk/walk0003.png'
import Walk4 from '../assets/sam_sprites/walk/walk0004.png'
import Walk5 from '../assets/sam_sprites/walk/walk0005.png'
import Walk6 from '../assets/sam_sprites/walk/walk0006.png'
import Walk7 from '../assets/sam_sprites/walk/walk0007.png'
import Walk8 from '../assets/sam_sprites/walk/walk0008.png'
import Walk9 from '../assets/sam_sprites/walk/walk0009.png'
import Walk10 from '../assets/sam_sprites/walk/walk0010.png'
import Walk11 from '../assets/sam_sprites/walk/walk0011.png'
import Walk12 from '../assets/sam_sprites/walk/walk0012.png'
import Walk13 from '../assets/sam_sprites/walk/walk0013.png'
import Walk14 from '../assets/sam_sprites/walk/walk0014.png'
import Walk15 from '../assets/sam_sprites/walk/walk0015.png'
import Walk16 from '../assets/sam_sprites/walk/walk0016.png'
import Walk17 from '../assets/sam_sprites/walk/walk0017.png'
import Walk18 from '../assets/sam_sprites/walk/walk0018.png'
import Walk19 from '../assets/sam_sprites/walk/walk0019.png'

import Turn1 from '../assets/sam_sprites/turn/turn0001.png'
import Turn2 from '../assets/sam_sprites/turn/turn0002.png'
import Turn3 from '../assets/sam_sprites/turn/turn0003.png'
import Turn4 from '../assets/sam_sprites/turn/turn0004.png'
import Turn5 from '../assets/sam_sprites/turn/turn0005.png'
import Turn6 from '../assets/sam_sprites/turn/turn0006.png'
import Turn7 from '../assets/sam_sprites/turn/turn0007.png'
import Turn8 from '../assets/sam_sprites/turn/turn0008.png'
import Turn9 from '../assets/sam_sprites/turn/turn0009.png'
import Turn10 from '../assets/sam_sprites/turn/turn0010.png'
import Turn11 from '../assets/sam_sprites/turn/turn0011.png'
import Turn12 from '../assets/sam_sprites/turn/turn0012.png'
import Turn13 from '../assets/sam_sprites/turn/turn0013.png'
import Turn14 from '../assets/sam_sprites/turn/turn0014.png'
import Turn15 from '../assets/sam_sprites/turn/turn0015.png'
import Turn16 from '../assets/sam_sprites/turn/turn0016.png'
import Turn17 from '../assets/sam_sprites/turn/turn0017.png'
import Turn18 from '../assets/sam_sprites/turn/turn0018.png'
import Turn19 from '../assets/sam_sprites/turn/turn0019.png'
import Turn20 from '../assets/sam_sprites/turn/turn0020.png'
import Turn21 from '../assets/sam_sprites/turn/turn0021.png'
import Turn22 from '../assets/sam_sprites/turn/turn0022.png'
import Turn23 from '../assets/sam_sprites/turn/turn0023.png'
import Turn24 from '../assets/sam_sprites/turn/turn0024.png'
import Turn25 from '../assets/sam_sprites/turn/turn0025.png'

import WalkToSit0001 from '../assets/sam_sprites/walktosit/walktosit0001.png'
import WalkToSit0002 from '../assets/sam_sprites/walktosit/walktosit0002.png'
import WalkToSit0003 from '../assets/sam_sprites/walktosit/walktosit0003.png'
import WalkToSit0004 from '../assets/sam_sprites/walktosit/walktosit0004.png'
import WalkToSit0005 from '../assets/sam_sprites/walktosit/walktosit0005.png'
import WalkToSit0006 from '../assets/sam_sprites/walktosit/walktosit0006.png'
import WalkToSit0007 from '../assets/sam_sprites/walktosit/walktosit0007.png'
import WalkToSit0008 from '../assets/sam_sprites/walktosit/walktosit0008.png'
import WalkToSit0009 from '../assets/sam_sprites/walktosit/walktosit0009.png'
import WalkToSit0010 from '../assets/sam_sprites/walktosit/walktosit0010.png'
import WalkToSit0011 from '../assets/sam_sprites/walktosit/walktosit0011.png'
import WalkToSit0012 from '../assets/sam_sprites/walktosit/walktosit0012.png'
import WalkToSit0013 from '../assets/sam_sprites/walktosit/walktosit0013.png'
import WalkToSit0014 from '../assets/sam_sprites/walktosit/walktosit0014.png'
import WalkToSit0015 from '../assets/sam_sprites/walktosit/walktosit0015.png'
import WalkToSit0016 from '../assets/sam_sprites/walktosit/walktosit0016.png'

import SitToWalk0001 from '../assets/sam_sprites/sittowalk/sittowalk0001.png'
import SitToWalk0002 from '../assets/sam_sprites/sittowalk/sittowalk0002.png'
import SitToWalk0003 from '../assets/sam_sprites/sittowalk/sittowalk0003.png'
import SitToWalk0004 from '../assets/sam_sprites/sittowalk/sittowalk0004.png'
import SitToWalk0005 from '../assets/sam_sprites/sittowalk/sittowalk0005.png'
import SitToWalk0006 from '../assets/sam_sprites/sittowalk/sittowalk0006.png'
import SitToWalk0007 from '../assets/sam_sprites/sittowalk/sittowalk0007.png'
import SitToWalk0008 from '../assets/sam_sprites/sittowalk/sittowalk0008.png'
import SitToWalk0009 from '../assets/sam_sprites/sittowalk/sittowalk0009.png'
import SitToWalk0010 from '../assets/sam_sprites/sittowalk/sittowalk0010.png'
import SitToWalk0011 from '../assets/sam_sprites/sittowalk/sittowalk0011.png'
import SitToWalk0012 from '../assets/sam_sprites/sittowalk/sittowalk0012.png'
import SitToWalk0013 from '../assets/sam_sprites/sittowalk/sittowalk0013.png'
import SitToWalk0014 from '../assets/sam_sprites/sittowalk/sittowalk0014.png'

import LookDirection0 from '../assets/sam_sprites/lookdirection/lookdirection0.png'
import LookDirection1 from '../assets/sam_sprites/lookdirection/lookdirection1.png'
import LookDirection2 from '../assets/sam_sprites/lookdirection/lookdirection2.png'
import LookDirection3 from '../assets/sam_sprites/lookdirection/lookdirection3.png'
import LookDirection4 from '../assets/sam_sprites/lookdirection/lookdirection4.png'
import LookDirection5 from '../assets/sam_sprites/lookdirection/lookdirection5.png'
import LookDirection6 from '../assets/sam_sprites/lookdirection/lookdirection6.png'
import LookDirection7 from '../assets/sam_sprites/lookdirection/lookdirection7.png'
import LookDirection8 from '../assets/sam_sprites/lookdirection/lookdirection8.png'
import LookDirection9 from '../assets/sam_sprites/lookdirection/lookdirection9.png'
import LookDirection10 from '../assets/sam_sprites/lookdirection/lookdirection10.png'
import LookDirection11 from '../assets/sam_sprites/lookdirection/lookdirection11.png'
import LookDirection12 from '../assets/sam_sprites/lookdirection/lookdirection12.png'
import LookDirection13 from '../assets/sam_sprites/lookdirection/lookdirection13.png'
import LookDirection14 from '../assets/sam_sprites/lookdirection/lookdirection14.png'
import LookDirection15 from '../assets/sam_sprites/lookdirection/lookdirection15.png'

const ImagesPlayer = ({ currentFrame, samRef, imageLoaded }) => {
    const walk0001 = useRef(null);
    const walk0002 = useRef(null);
    const walk0003 = useRef(null);
    const walk0004 = useRef(null);
    const walk0005 = useRef(null);
    const walk0006 = useRef(null);
    const walk0007 = useRef(null);
    const walk0008 = useRef(null);
    const walk0009 = useRef(null);
    const walk0010 = useRef(null);
    const walk0011 = useRef(null);
    const walk0012 = useRef(null);
    const walk0013 = useRef(null);
    const walk0014 = useRef(null);
    const walk0015 = useRef(null);
    const walk0016 = useRef(null);
    const walk0017 = useRef(null);
    const walk0018 = useRef(null);
    const walk0019 = useRef(null);

    const turn0001 = useRef(null);
    const turn0002 = useRef(null);
    const turn0003 = useRef(null);
    const turn0004 = useRef(null);
    const turn0005 = useRef(null);
    const turn0006 = useRef(null);
    const turn0007 = useRef(null);
    const turn0008 = useRef(null);
    const turn0009 = useRef(null);
    const turn0010 = useRef(null);
    const turn0011 = useRef(null);
    const turn0012 = useRef(null);
    const turn0013 = useRef(null);
    const turn0014 = useRef(null);
    const turn0015 = useRef(null);
    const turn0016 = useRef(null);
    const turn0017 = useRef(null);
    const turn0018 = useRef(null);
    const turn0019 = useRef(null);
    const turn0020 = useRef(null);
    const turn0021 = useRef(null);
    const turn0022 = useRef(null);
    const turn0023 = useRef(null);
    const turn0024 = useRef(null);
    const turn0025 = useRef(null);

    const walkToSit0001 = useRef(null);
    const walkToSit0002 = useRef(null);
    const walkToSit0003 = useRef(null);
    const walkToSit0004 = useRef(null);
    const walkToSit0005 = useRef(null);
    const walkToSit0006 = useRef(null);
    const walkToSit0007 = useRef(null);
    const walkToSit0008 = useRef(null);
    const walkToSit0009 = useRef(null);
    const walkToSit0010 = useRef(null);
    const walkToSit0011 = useRef(null);
    const walkToSit0012 = useRef(null);
    const walkToSit0013 = useRef(null);
    const walkToSit0014 = useRef(null);
    const walkToSit0015 = useRef(null);
    const walkToSit0016 = useRef(null);

    const sitToWalk0001 = useRef(null);
    const sitToWalk0002 = useRef(null);
    const sitToWalk0003 = useRef(null);
    const sitToWalk0004 = useRef(null);
    const sitToWalk0005 = useRef(null);
    const sitToWalk0006 = useRef(null);
    const sitToWalk0007 = useRef(null);
    const sitToWalk0008 = useRef(null);
    const sitToWalk0009 = useRef(null);
    const sitToWalk0010 = useRef(null);
    const sitToWalk0011 = useRef(null);
    const sitToWalk0012 = useRef(null);
    const sitToWalk0013 = useRef(null);
    const sitToWalk0014 = useRef(null);
    
    const lookDirection0 = useRef(null);
    const lookDirection1 = useRef(null);
    const lookDirection2 = useRef(null);
    const lookDirection3 = useRef(null);
    const lookDirection4 = useRef(null);
    const lookDirection5 = useRef(null);
    const lookDirection6 = useRef(null);
    const lookDirection7 = useRef(null);
    const lookDirection8 = useRef(null);
    const lookDirection9 = useRef(null);
    const lookDirection10 = useRef(null);
    const lookDirection11 = useRef(null);
    const lookDirection12 = useRef(null);
    const lookDirection13 = useRef(null);
    const lookDirection14 = useRef(null);
    const lookDirection15 = useRef(null);

    const [currentImage, setCurrentImage] = useState(walk0001);
    useEffect(() => {
        const refLibrary = {
            'walk0001': walk0001,
            'walk0002': walk0002,
            'walk0003': walk0003,
            'walk0004': walk0004,
            'walk0005': walk0005,
            'walk0006': walk0006,
            'walk0007': walk0007,
            'walk0008': walk0008,
            'walk0009': walk0009,
            'walk0010': walk0010,
            'walk0011': walk0011,
            'walk0012': walk0012,
            'walk0013': walk0013,
            'walk0014': walk0014,
            'walk0015': walk0015,
            'walk0016': walk0016,
            'walk0017': walk0017,
            'walk0018': walk0018,
            'walk0019': walk0019,
            'turn0001': turn0001,
            'turn0002': turn0002,
            'turn0003': turn0003,
            'turn0004': turn0004,
            'turn0005': turn0005,
            'turn0006': turn0006,
            'turn0007': turn0007,
            'turn0008': turn0008,
            'turn0009': turn0009,
            'turn0010': turn0010,
            'turn0011': turn0011,
            'turn0012': turn0012,
            'turn0013': turn0013,
            'turn0014': turn0014,
            'turn0015': turn0015,
            'turn0016': turn0016,
            'turn0017': turn0017,
            'turn0018': turn0018,
            'turn0019': turn0019,
            'turn0020': turn0020,
            'turn0021': turn0021,
            'turn0022': turn0022,
            'turn0023': turn0023,
            'turn0024': turn0024,
            'turn0025': turn0025,
            'walkToSit0001': walkToSit0001,
            'walkToSit0002': walkToSit0002,
            'walkToSit0003': walkToSit0003,
            'walkToSit0004': walkToSit0004,
            'walkToSit0005': walkToSit0005,
            'walkToSit0006': walkToSit0006,
            'walkToSit0007': walkToSit0007,
            'walkToSit0008': walkToSit0008,
            'walkToSit0009': walkToSit0009,
            'walkToSit0010': walkToSit0010,
            'walkToSit0011': walkToSit0011,
            'walkToSit0012': walkToSit0012,
            'walkToSit0013': walkToSit0013,
            'walkToSit0014': walkToSit0014,
            'walkToSit0015': walkToSit0015,
            'walkToSit0016': walkToSit0016,
            'sitToWalk0001': sitToWalk0001,
            'sitToWalk0002': sitToWalk0002,
            'sitToWalk0003': sitToWalk0003,
            'sitToWalk0004': sitToWalk0004,
            'sitToWalk0005': sitToWalk0005,
            'sitToWalk0006': sitToWalk0006,
            'sitToWalk0007': sitToWalk0007,
            'sitToWalk0008': sitToWalk0008,
            'sitToWalk0009': sitToWalk0009,
            'sitToWalk0010': sitToWalk0010,
            'sitToWalk0011': sitToWalk0011,
            'sitToWalk0012': sitToWalk0012,
            'sitToWalk0013': sitToWalk0013,
            'sitToWalk0014': sitToWalk0014,
            'lookDirection0': lookDirection0,
            'lookDirection1': lookDirection1,
            'lookDirection2': lookDirection2,
            'lookDirection3': lookDirection3,
            'lookDirection4': lookDirection4,
            'lookDirection5': lookDirection5,
            'lookDirection6': lookDirection6,
            'lookDirection7': lookDirection7,
            'lookDirection8': lookDirection8,
            'lookDirection9': lookDirection9,
            'lookDirection10': lookDirection10,
            'lookDirection11': lookDirection11,
            'lookDirection12': lookDirection12,
            'lookDirection13': lookDirection13,
            'lookDirection14': lookDirection14,
            'lookDirection15': lookDirection15,
        }
        currentImage.current.className = 'sam';
        const nextRef = refLibrary[currentFrame.image]
        setCurrentImage(nextRef);
        nextRef.current.className = `sam active ${currentFrame.facing}`;
    }, [currentFrame, currentImage])

    const testFunc = (e) => {
        const img = e.target;
        img.decode()
        .then(() => {
            imageLoaded();
        })
        .catch((encodingError) => {
            console.log(encodingError);
        }) 
    }

    return (
        <div ref={samRef} style={currentFrame.style} className="movement-container">
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walk0001} className='sam' src={Walk1} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walk0002} className='sam' src={Walk2} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walk0003} className='sam' src={Walk3} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walk0004} className='sam' src={Walk4} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walk0005} className='sam' src={Walk5} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walk0006} className='sam' src={Walk6} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walk0007} className='sam' src={Walk7} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walk0008} className='sam' src={Walk8} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walk0009} className='sam' src={Walk9} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walk0010} className='sam' src={Walk10} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walk0011} className='sam' src={Walk11} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walk0012} className='sam' src={Walk12} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walk0013} className='sam' src={Walk13} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walk0014} className='sam' src={Walk14} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walk0015} className='sam' src={Walk15} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walk0016} className='sam' src={Walk16} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walk0017} className='sam' src={Walk17} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walk0018} className='sam' src={Walk18} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walk0019} className='sam' src={Walk19} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={turn0001} className='sam' src={Turn1} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={turn0002} className='sam' src={Turn2} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={turn0003} className='sam' src={Turn3} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={turn0004} className='sam' src={Turn4} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={turn0005} className='sam' src={Turn5} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={turn0006} className='sam' src={Turn6} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={turn0007} className='sam' src={Turn7} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={turn0008} className='sam' src={Turn8} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={turn0009} className='sam' src={Turn9} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={turn0010} className='sam' src={Turn10} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={turn0011} className='sam' src={Turn11} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={turn0012} className='sam' src={Turn12} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={turn0013} className='sam' src={Turn13} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={turn0014} className='sam' src={Turn14} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={turn0015} className='sam' src={Turn15} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={turn0016} className='sam' src={Turn16} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={turn0017} className='sam' src={Turn17} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={turn0018} className='sam' src={Turn18} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={turn0019} className='sam' src={Turn19} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={turn0020} className='sam' src={Turn20} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={turn0021} className='sam' src={Turn21} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={turn0022} className='sam' src={Turn22} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={turn0023} className='sam' src={Turn23} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={turn0024} className='sam' src={Turn24} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={turn0025} className='sam' src={Turn25} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walkToSit0001} className='sam' src={WalkToSit0001} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walkToSit0002} className='sam' src={WalkToSit0002} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walkToSit0003} className='sam' src={WalkToSit0003} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walkToSit0004} className='sam' src={WalkToSit0004} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walkToSit0005} className='sam' src={WalkToSit0005} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walkToSit0006} className='sam' src={WalkToSit0006} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walkToSit0007} className='sam' src={WalkToSit0007} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walkToSit0008} className='sam' src={WalkToSit0008} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walkToSit0009} className='sam' src={WalkToSit0009} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walkToSit0010} className='sam' src={WalkToSit0010} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walkToSit0011} className='sam' src={WalkToSit0011} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walkToSit0012} className='sam' src={WalkToSit0012} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walkToSit0013} className='sam' src={WalkToSit0013} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walkToSit0014} className='sam' src={WalkToSit0014} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walkToSit0015} className='sam' src={WalkToSit0015} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={walkToSit0016} className='sam' src={WalkToSit0016} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={sitToWalk0001} className='sam' src={SitToWalk0001} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={sitToWalk0002} className='sam' src={SitToWalk0002} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={sitToWalk0003} className='sam' src={SitToWalk0003} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={sitToWalk0004} className='sam' src={SitToWalk0004} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={sitToWalk0005} className='sam' src={SitToWalk0005} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={sitToWalk0006} className='sam' src={SitToWalk0006} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={sitToWalk0007} className='sam' src={SitToWalk0007} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={sitToWalk0008} className='sam' src={SitToWalk0008} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={sitToWalk0009} className='sam' src={SitToWalk0009} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={sitToWalk0010} className='sam' src={SitToWalk0010} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={sitToWalk0011} className='sam' src={SitToWalk0011} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={sitToWalk0012} className='sam' src={SitToWalk0012} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={sitToWalk0013} className='sam' src={SitToWalk0013} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={sitToWalk0014} className='sam' src={SitToWalk0014} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={lookDirection0} className='sam' src={LookDirection0} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={lookDirection1} className='sam' src={LookDirection1} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={lookDirection2} className='sam' src={LookDirection2} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={lookDirection3} className='sam' src={LookDirection3} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={lookDirection4} className='sam' src={LookDirection4} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={lookDirection5} className='sam' src={LookDirection5} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={lookDirection6} className='sam' src={LookDirection6} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={lookDirection7} className='sam' src={LookDirection7} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={lookDirection8} className='sam' src={LookDirection8} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={lookDirection9} className='sam' src={LookDirection9} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={lookDirection10} className='sam' src={LookDirection10} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={lookDirection11} className='sam' src={LookDirection11} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={lookDirection12} className='sam' src={LookDirection12} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={lookDirection13} className='sam' src={LookDirection13} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={lookDirection14} className='sam' src={LookDirection14} alt='' />
            <img onLoad={(e) => testFunc(e)} draggable="false" ref={lookDirection15} className='sam' src={LookDirection15} alt='' />
        </div>
    )
}

export default ImagesPlayer
