import React, { useEffect, useRef, useState } from 'react'
import FancySelect from '../fancySelect'
import endflagBottom from '../../assets/flag bottom.png'
import startFlagBottom from '../../assets/start flag bottom.png'
import DegreeSelector from './degreeSelector'

const SelectorsComponent = ({ setRoot, setScaleType, selectorsParentRef, genNext, degreeOptions, setDegreeOptions, curDegree, curNoteSeq }) => {

    const selectorsRef = useRef(null);
    const [selectorsLeftStyle, setSelectorsLeftStyle] = useState(30);

    String.prototype.replaceAt = function (index, replacement) {
        return this.substring(0, index) + replacement + this.substring(index + replacement.length);
    }
    const updateAccidental = (accidental) => {
        if (accidental) {
            setRoot((root) => {
                if (root) return root.replaceAt(1, accidental);
            });
        }
    }
    const updateRootLetter = (note) => {
        if (note) {
            setRoot((root) => {
                if (root) return root.replaceAt(0, note);
            });
        }
    }

    const recenterParent = () => {
        const parent = selectorsParentRef.current.offsetWidth;
        const child = selectorsRef.current.offsetWidth;
        setSelectorsLeftStyle(
            (((parent - child) / parent) / 2) * 100
        )
    }

    useEffect(() => {
        if (degreeOptions && curDegree){
            if (!degreeOptions.includes(curDegree)){ 
                genNext();
            }
        }
    }, [degreeOptions])

    useEffect(() => {
        recenterParent();
    }, [])

    return (
        <div
            className="selectors-container"
            ref={selectorsRef}
            style={{
                left: selectorsLeftStyle + '%'
            }}
        >
            <FancySelect
                setState={updateRootLetter}
                options={['C', 'D', 'E', 'F', 'G', 'A', 'B']}
                recenterParent={recenterParent}
            />
            <FancySelect
                setState={updateAccidental}
                options={['♮', '♯', '♭']}
                recenterParent={recenterParent}
            />
            <div style={{ width: "10px" }} />
            <FancySelect
                setState={setScaleType}
                options={
                    ['Major', 'Minor', 'Harmonic Minor', 'Natural Minor', 'Dorian',
                        'Phrygian', 'Lydian', 'Mixolydian', 'Aeolian', 'Locrian']}
                recenterParent={recenterParent}
            />
            <div style={{ width: "10px" }} />
            <div className="text-container">
                in&nbsp;

                <DegreeSelector
                    curDegree={curDegree}
                    degreeOptions={degreeOptions}
                    setDegreeOptions={setDegreeOptions}
                />
                &nbsp;from&nbsp;
                <img className="flag" style={{ marginRight: "1px" }} src={startFlagBottom} />
                <span className="bold">{curNoteSeq ? curNoteSeq.startNote.slice(0, -1) : ''}&nbsp;</span>
                to&nbsp;
                <img className="flag" src={endflagBottom} />
                <span className="bold">{curNoteSeq ? curNoteSeq.endNote.slice(0, -1) : ''}</span>
            </div>
        </div>
    )
}

export default SelectorsComponent
