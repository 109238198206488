import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../css/nav.scss";

const Nav = () => {
  const [navShadow, setNavShadow] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", function (e) {
      if (window.scrollY <= 0) {
        setNavShadow(false);
      } else {
        setNavShadow(true);
      }
    });
  }, []);

  return (
    <div className={`nav-container ${navShadow}`}>
      <div className="page-title">
        <Link className="title" to={{ pathname: "/" }}>
          michael du
        </Link>
        <Link className="title right-align" to={{ pathname: "/scalepractice" }}>
          ♩ <span className="mobile-hide">scale practice tool</span>
        </Link>
      </div>
    </div>
  );
};

export default Nav;
