import React, { useState, useEffect } from 'react'
import micDisclosureImg from '../../assets/mic-disclosure.png'

const MicDisclosure = ({ micPermissions }) => {

    // Little component to allow for some smoother pop up
    // functionality on mic disclosure
    const [popupState, setPopupState] = useState(false);
    const [delayToggle, setDelayToggle] = useState(true);

    setTimeout(() => {
        if (micPermissions === false){
            if (!delayToggle){
                setPopupState(true);
            }
        }
        else {
            setPopupState(false);
        }
        setDelayToggle(false);
    }, 1000)

    useEffect(() => {
        if (!delayToggle){
            setPopupState(!micPermissions);
        }
    }, [micPermissions])
    return (
        <div className={`mic-permissions-popup ${popupState}`}>
            <img src={micDisclosureImg} alt="mic disclosure popup"/>
        </div>
    )
}

export default MicDisclosure
