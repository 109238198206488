import React from 'react'
import BackBuildings from '../assets/background/back buildings no sun.png'
import FrontBuildings from '../assets/background/front buildlings state2.png'
import Window from '../assets/background/window.png'
import Sky from '../assets/background/sky2.jpg'
import Cloud1 from '../assets/background/cloud1.png'
import Cloud2 from '../assets/background/cloud2.png'
import FrontBuildingLights from '../components/frontBuildingsLights'
import BackBuildingsLights from '../components/backBuildingsLight'
const Background = ({imageLoaded}) => {
    return (
        <div className="background-container">
            <img onLoad={imageLoaded} className="sky" src={Sky} draggable="false" alt="background sky"/>
            <img onLoad={imageLoaded} className="cloud1" src={Cloud1} draggable="false" alt="background cloud 1"/>
            <img onLoad={imageLoaded} className="cloud2" src={Cloud2} draggable="false" alt="background cloud 2"/>
            <div className="aspect-ratio-wrapper">
                <div className="aspect-ratio">
                    <div className="sun"/>
                </div>
            </div>
            <img onLoad={imageLoaded} className="front-buildings" src={FrontBuildings} alt='background buildings2'  draggable="false"/>
            <FrontBuildingLights/>
            {/* <GodRays/> */}
            <img onLoad={imageLoaded} className="back-buildings" src={BackBuildings} alt='background buildings1'  draggable="false"/>
            <BackBuildingsLights/>
            <img onLoad={imageLoaded} className="window" src={Window} alt='Window Frame'  draggable="false"/>
            {/* <img className="static-sam" src={Sam} alt='Sam' /> */}
        </div>
    )
}

export default Background
