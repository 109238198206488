import React from 'react'
import LoadingCover from '../components/guitarComponents/loadingCover'
import ScalePractice from '../components/guitarComponents/scalePractice'
import SmallScreenOverlay from '../components/guitarComponents/smallScreenOverlay'
import Nav from '../components/nav'
const Guitar = () => {
    return (
        <div>
            <Nav/>
            <ScalePractice/>
            <SmallScreenOverlay/>
            <LoadingCover/>
        </div>
    )
}

export default Guitar
