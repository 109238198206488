import React, { useState, useEffect } from 'react'

const LoadingCover = () => {
    const [count, setCount] = useState(0);
    const [toggle, setToggle] = useState(true);
    useEffect(() => {
        let id;
        id = setInterval(() => setCount(
            (oldCount) => {
                if (toggle) {
                    if (oldCount > 100) {
                        setToggle(false);
                    }
                    return oldCount + 1
                }
            }
        ), 5);
        return () => {
            clearInterval(id);
        };
    }, []);
    return (
        <div className={`loading-cover ${toggle}`}>
            <div className={`loading-bar loading`}>
                <div style={{ width: count + '%' }} className="progress" />
            </div>
        </div>
    )
}

export default LoadingCover
