import React from 'react'
import '../css/body.scss'
import IndividualSkill from './individualSkill'
import IntroImage from '../assets/IntroImage.jpg'
import MyVisaSource from '../assets/MyVisaSource.png'
import SwiftAdmit from '../assets/SwiftAdmit.jpg'
import UniversityOfToronto from '../assets/UniversityOfToronto.png'
import AWS from '../assets/skills logos/AWS.jpg'
import CPlusPlus from '../assets/skills logos/C++.jpg'
import CSS from '../assets/skills logos/CSS.jpg'
import Figma from '../assets/skills logos/Figma.jpg'
import Git from '../assets/skills logos/Git.jpg'
import HTML from '../assets/skills logos/HTML.jpg'
import Hubspot from '../assets/skills logos/Hubspot.jpg'
import IBMCloud from '../assets/skills logos/IBM Cloud.jpg'
import Java from '../assets/skills logos/Java.jpg'
import JS from '../assets/skills logos/JS.jpg'
import NodeJS from '../assets/skills logos/NodeJS.jpg'
import Python from '../assets/skills logos/Python.jpg'
import ReactLogo from '../assets/skills logos/React.jpg'
import Shopify from '../assets/skills logos/Shopify.jpg'
import SQL from '../assets/skills logos/SQL.jpg'
import Wordpress from '../assets/skills logos/Wordpress.jpg'
import LinkedIn from '../assets/skills logos/linkedin.png'
import Mail from '../assets/skills logos/Mail.png'
import susEye from '../assets/sameye.png'
import Resume from '../assets/MichaelDu-Resume.pdf'
const Body = () => {
    return (
        <div className="body-container">
            <h1>Hi. I'm Michael.<br /> A Full Stack Developer.</h1>
            <div className="intro-section">
                <div className="text-container">
                    <p>
                    Passionate about music, badminton, and also an (obvious) cat lover. <br/>Remember to say hi to <span className="samya-text">Samya</span> on her windowsill!
                    </p>
                </div>
                <div className="image-container">
                    <img src={IntroImage} alt="Samya and I working hard :)" />
                </div>
            </div>
            <h2 className="section-break">
                My Journey.
                <a href={Resume} className="resume-link"  rel="noreferrer" target="_blank">View Resume</a>
            </h2>
            <div className="two-col-split first-child">
                <div className="left-item">
                    <img src={SwiftAdmit} alt="SwiftAdmit Logo" />
                </div>
                <div className="right-item">
                    <div className="company-name">
                        SwiftAdmit
                    </div>
                    <div className="position">
                        Lead Developer, Since February 2021
                    </div>
                    <div className="location">
                        Toronto, ON
                    </div>
                    <p>
                        SwiftAdmit is a platform built to aide international students on their education journey abroad. We’ve felt how overwhelming of a process this can be for students and sought to provide a centralized, easy to use solution. By combining university applications, study permit guidance, and student loans into one platform, students can focus on their experience abroad instead of the process getting there.
                        <br /><br />
                        This is the most exciting project I’ve had the opportunity to lead. From the start, I’ve been directly involved in every piece from front end UI design to database schema structure. Managing each individual piece of technology and integrating them together has been very challenging, but engaging work.
                        <br /><br />
                        SwiftAdmit’s main stack is React, Express, PostgreSQL, and NodeJS, supported by the AWS suite of services.
                    </p>
                </div>
            </div>
            <div className="two-col-split">
                <div className="left-item">
                    <img src={MyVisaSource} alt="My Visa Source Logo" />
                </div>
                <div className="right-item">
                    <div className="company-name">
                        My Visa Source
                    </div>
                    <div className="position">
                        Lead Developer, Since July 2018
                    </div>
                    <div className="location">
                        Toronto, ON
                    </div>
                    <p>
                        At My Visa Source I had the opportunity to grow as a professional developer, immersing myself in a wide range of technologies and responsibilities as the lead developer.
                        <br /><br />
                        One of the main projects during my time here was leading a full front to back website redesign. This was an opportunity to focus on what a seamless online experience can do to improve conversions, pushing an old industry into capturing web based leads. Once published, the redesign was a huge success. Our bounce rate dropped by 18.5% and conversion rate went up by 65% in the coming months.
                        <br /><br />
                        Alongside the redesign, I had my hand in all of the digital side maintenance that a high volume law firm needs to run its day to day. With Hubspot as our primary CRM/CMS, I worked closely with immigration lawyers to maintain and create workflows, ensuring client information flows seamlessly in each step of the process.
                    </p>
                </div>
            </div>
            <div className="two-col-split">
                <div className="left-item">
                    <img src={UniversityOfToronto} alt="University of Toronto Logo" />
                </div>
                <div className="right-item">
                    <div className="company-name">
                        University of Toronto
                    </div>
                    <div className="position">
                        B.S. in Computer Science and Statistics, graduated May 2020
                    </div>
                    <div className="location">
                        Toronto, ON
                    </div>
                    <p>
                        I had the wonderful opportunity to complete my Bachelors of Science at the University of Toronto. Here I discovered my passion for tech and found a great launchpad to kickstart my career as a developer.
                    </p>
                </div>
            </div>
            <h2 className="section-break">My Skills.</h2>
            <div className="skills-container">
                <IndividualSkill name="Javascript" image={JS} />
                <IndividualSkill name="C++" image={CPlusPlus} />
                <IndividualSkill name="CSS" image={CSS} />
                <IndividualSkill name="Git" image={Git} />
                <IndividualSkill name="HTML" image={HTML} />
                <IndividualSkill name="Java" image={Java} />
                <IndividualSkill name="Amazon Web Services" image={AWS} />
                <IndividualSkill name="NodeJS" image={NodeJS} />
                <IndividualSkill name="Python" image={Python} />
                <IndividualSkill name="React" image={ReactLogo} />
                <IndividualSkill name="SQL" image={SQL} />
                <IndividualSkill name="Hubspot" image={Hubspot} />
                <IndividualSkill name="IBM Cloud" image={IBMCloud} />
                <IndividualSkill name="Shopify" image={Shopify} />
                <IndividualSkill name="Figma" image={Figma} />
                <IndividualSkill name="Wordpress" image={Wordpress} />
            </div>
            <div className="connect-container">
                <div className="intro-text">
                    Connect with me on LinkedIn or email me with any exciting opportunities!
                </div>
                <div className="icon-container">
                    <a href="https://www.linkedin.com/in/michael-du-a306191b3/" className="individual-icon" rel="noreferrer" target="_blank">
                        <img src={LinkedIn} alt="LinkedIn Logo"/>
                    </a>
                    <a href="mailto:michaelpydu@gmail.com" className="individual-icon">
                        <img src={Mail} alt="Email"/>
                    </a>
                </div>
            </div>
            <img src={susEye} className="susya" alt="sus samya"/>
        </div>
    )
}

export default Body