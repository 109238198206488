import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom';
import Fretboard from '../../components/guitarComponents/fretboard'
import { initPitchFinder } from '../../components/guitarComponents/pitchFinder'
import {
    singleNoteFlatSharpCheck,
    generateScale,
    generateKeyConstants,
    generateNextNoteSeq
} from '../../components/helpers'
import '../../css/guitar.scss'
import guitarImage from '../../assets/guitar final.png'
import MicDisclosure from '../../components/guitarComponents/micDisclosure'
import SelectorsComponent from '../../components/guitarComponents/selectorsComponent'

const Guitar = () => {

    const [root, setRoot] = useState('C♮0');
    const [scaleType, setScaleType] = useState('Major');
    const [keyConstants, setKeyConstants] = useState(null);
    const [note, setNote] = useState(null);
    const [micPermissions, setMicPermissions] = useState(false);
    const [curNoteSeq, setCurNoteSeq] = useState(null);
    const [curSeqIndex, setCurSeqIndex] = useState(0);
    const [curDegree, setCurDegree] = useState(null);
    const [degreeOptions, setDegreeOptions] = useState([2, 3, 4, 5, 6, 7]);

    const selectorsParentRef = useRef(null);

    const getDegree = () => {
        const degree = degreeOptions[Math.floor(Math.random() * degreeOptions.length)];
        setCurDegree(degree);
        return degree;
    }

    const genNext = (startNote = null) => {
        if (keyConstants) {
            setCurSeqIndex(0);
            setCurNoteSeq(
                generateNextNoteSeq(keyConstants.scaleNoteList, scaleType, startNote, getDegree())
            );
        }
    }

    const notePlayedHandler = (note) => {
        if (curNoteSeq.noteSeq[curSeqIndex] === note) {
            setCurSeqIndex((prev) => { return prev + 1; });
            if ((curNoteSeq.noteSeq.length - 1) === curSeqIndex) {
                genNext(curNoteSeq.endNote);
            }
        }
    }

    useEffect(() => {
        if (note) {
            notePlayedHandler(note);
        }
    }, [note])

    useEffect(() => {
        initPitchFinder(setNote, setMicPermissions);
    }, [])


    useEffect(() => {
        const scaleNotes = generateScale(root, scaleType);
        const keyConstants = generateKeyConstants(scaleNotes);
        setKeyConstants(keyConstants);
    }, [root, scaleType])

    useEffect(() => {
        genNext();
    }, [keyConstants])

    return (
        <div className="page-container">
            <div className="debug-section">
                {curNoteSeq ?
                    <>
                        <div>
                            From {curNoteSeq.startNote} To {curNoteSeq.endNote} in {curNoteSeq.degree}
                            <br />
                            {JSON.stringify(curNoteSeq)}
                        </div>
                    </>
                    : <></>
                }
                {JSON.stringify(keyConstants)}
                <br />
                root: {root}
                <br />
                curIndex: {curSeqIndex}
            </div>
            <div className="page-layout-container" ref={selectorsParentRef}>
                <MicDisclosure micPermissions={micPermissions} />
                <div className="guitar-container">
                    <img className="guitar-image" src={guitarImage} draggable="false" alt="DRAWING of guitar >>"/>
                    <div className="fretboard-size-container">
                        {keyConstants && curNoteSeq ?
                            <Fretboard
                                noteList={keyConstants.fullNoteList}
                                currentNote={singleNoteFlatSharpCheck(note, keyConstants.sharpsOrFlats)}
                                endNote={curNoteSeq.endNote}
                                startNote={curNoteSeq.startNote}
                                nextNote={curNoteSeq.noteSeq[curSeqIndex]}
                                notePlayedHandler={notePlayedHandler}
                            />
                            :
                            <></>}
                    </div>
                </div>
                <SelectorsComponent
                    setRoot={setRoot}
                    setScaleType={setScaleType}
                    curDegree={curDegree}
                    selectorsParentRef={selectorsParentRef}
                    genNext={genNext}
                    degreeOptions={degreeOptions}
                    setDegreeOptions={setDegreeOptions}
                    curNoteSeq={curNoteSeq}
                />

                <div className="sequence-container">
                    {curNoteSeq ? curNoteSeq.noteSeq.map((value, i) => {
                        return (
                            <div key={i} className={`sequence-note-container ${i === curSeqIndex ? 'active' : ''}`}>
                                {value}
                            </div>
                        )
                    })
                        :
                        <></>}
                </div>
            </div>
        </div>

    )
}

export default Guitar
