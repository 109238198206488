import React, { useEffect, useState } from 'react'
import Buildings from '../assets/background/front buildings state1.png'
const FrontBuildingLights = () => {
    const [windowStates, setWindowStates] = useState([
        false, false, false, false, false, false
    ])
    useEffect(() => {
        const timer = setInterval(() => {
            setWindowStates(prev => {
                var output = [];
                for (var state of prev) {
                    const outputState = (Math.random() < 0.1) ? !state : state;
                    output.push(outputState);
                }
                return output;
            })
        }, 10000);
        return () => clearInterval(timer);
    }, []);
    return (
        <>
            <img className={`front-buildings window1 windowToggle ${windowStates[0] === true ? 'active' : 'inactive'}`} src={Buildings} alt='' />
            <img className={`front-buildings window2 windowToggle ${windowStates[1] === true ? 'active' : 'inactive'}`} src={Buildings} alt='' />
            <img className={`front-buildings window3 windowToggle ${windowStates[2] === true ? 'active' : 'inactive'}`} src={Buildings} alt='' />
            <img className={`front-buildings window4 windowToggle ${windowStates[3] === true ? 'active' : 'inactive'}`} src={Buildings} alt='' />
            <img className={`front-buildings window5 windowToggle ${windowStates[4] === true ? 'active' : 'inactive'}`} src={Buildings} alt='' />
            <img className={`front-buildings window6 windowToggle ${windowStates[5] === true ? 'active' : 'inactive'}`} src={Buildings} alt='' />
        </>
    )
}

export default FrontBuildingLights
