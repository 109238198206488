import React, { useRef, useEffect, useState, useCallback } from 'react'
import '../css/fancySelect.scss'


const FancySelect = ({setState, options, recenterParent}) => {
    // Each line's height
    const heightConst = 50;
    // Offset required because hidden overflow container shifted up by 2 rows
    // Which shifts all the px calculations
    const heightOffset = heightConst * 2;
    const draggableEle = useRef(null);
    const parentContainer = useRef(null);
    const widthContainer = useRef(null);
    const [activeToggle, setActiveToggle] = useState(false);
    const [eleWidth, setEleWidth] = useState(100);
    const [coverWidth, setCoverWidth] = useState(100);
    const [curState, _setCurState] = useState(options[0]);
    const curStateRef = useRef(null);
    const setCurState = data => {
        curStateRef.current = data;
        _setCurState(data);
    };
    const [rel, _setRel] = useState(0);
    const relRef = useRef(rel);
    const setRel = data => {
        relRef.current = data;
        _setRel(data);
    };
    const [pos, _setPos] = useState(heightOffset);
    const posRef = useRef(pos);
    const setPos = data => {
        posRef.current = data;
        _setPos(data);
    };
    const [dragging, _setDragging] = useState(false);
    const draggingRef = useRef(dragging);
    const setDragging = data => {
        draggingRef.current = data;
        _setDragging(data);
    };

    const updateWidthContainer = (pos) => {
        const currentLabel = widthContainer.current.innerText;
        const nextLabelIndex = -((Math.round(pos / heightConst) * heightConst) - heightOffset) / heightConst;
        const nextLabel = options[nextLabelIndex];
        if (!(nextLabel === currentLabel)){
            widthContainer.current.innerText = nextLabel
            setCurState(nextLabel);
            setEleWidth(widthContainer.current.offsetWidth);
        }
    }

    const onMouseMove = useCallback((e) => {
        if (!draggingRef.current) return
        var nextPos = e.pageY - relRef.current;
        if (nextPos >= (0 + heightOffset)){
            nextPos = (0 + heightOffset);
        }
        else if (nextPos <= -(heightConst * (options.length - 1) - heightOffset)){
            nextPos = -(heightConst * (options.length - 1) - heightOffset);
        }
        setPos(nextPos);
        updateWidthContainer(posRef.current);
        e.stopPropagation();
        e.preventDefault();
    }, [])

    const onMouseDown = useCallback((e) => {
        setDragging(true);
        if (e.button !== 0) return
        const parentPos = parentContainer.current.getBoundingClientRect();
        const optionsPos = draggableEle.current.getBoundingClientRect();
        setRel(parentPos.y + e.pageY - optionsPos.y - heightOffset);
        e.stopPropagation();
        e.preventDefault();
    }, []);

    const snapToGrid = (pos) => {
        setPos(Math.round(pos / heightConst) * heightConst);
    }

    const onMouseUp = useCallback((e) => {
        setDragging(false);
        setActiveToggle(false);
        snapToGrid(posRef.current);
        if (curStateRef.current){
            setState(curStateRef.current);
        }
        e.stopPropagation();
        e.preventDefault();
        recenterParent();
    }, []);

    useEffect(() => {
        if (dragging){
            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
        }
        else {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        }
    }, [dragging])

    useEffect(() => {
        setTimeout(() => {
            setEleWidth(widthContainer.current.offsetWidth);
        }, 200)
        setEleWidth(widthContainer.current.offsetWidth);
    }, [widthContainer])

    useEffect(() => {
        setCoverWidth(draggableEle.current.offsetWidth);
    }, [draggableEle])

    return (
        <>
        <div 
            style = {{ width: eleWidth + "px" }} 
            ref={parentContainer} 
            className="select-container"
            onMouseEnter={() => setActiveToggle(true)}
            onMouseLeave={() => {
                if (!dragging) setActiveToggle(false)
            }}
        >
            <div 
                style = {{ width: coverWidth + "px" }} 
                className={`overflow-container ${activeToggle ? 'active': ''}`}
            >
                <div className="overflow top gradient"/>
                <div className="overflow bottom gradient"/>
                <div className={`overflow top ${activeToggle ? 'active': ''}`}/>
                <div className={`overflow bottom ${activeToggle ? 'active': ''}`}/>
                <div 
                    style={{ top: pos + "px" }} 
                    className="draggable-container"
                    onMouseDown={onMouseDown}
                    ref={draggableEle}
                >
                    { options.map(option => 
                        <div key={ option } className="select-option">{ option }</div>
                    )}
                </div>
                <div ref={widthContainer} className="width-container">{curState}</div>
            </div>
        </div>
        </>
    )
}

export default FancySelect
