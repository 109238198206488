import React, { useState, useEffect, useRef, useCallback } from 'react'
import ImagesPlayer from './imagesPlayer'
import { moveLogic, round, mouseAngleToImage } from './imageQueueLogic'
const Sam = ({ imageLoaded }) => {
    const [currentFrame, setCurrentFrame] = useState(
        {
            image: 'walkToSit0016',
            style: {
                left: '0%'
            },
            facing: 'right'
        }
    );
    const [frameQueue, setFrameQueue] = useState([]); // list of frames to play
    const [currentPos, setCurrentPos] = useState(0); // current frame
    const [isIdle, setIsIdle] = useState(true);
    const [lookDirectionAngle, setLookDirectionAngle] = useState(0);
    const samContainer = useRef(null);
    const samRef = useRef(null);
    const samClickHandler = (e) => {
        if (isIdle) {
            // get click pos, get frames to move Samya there
            const rect = samContainer.current.getBoundingClientRect();
            var clickPos = e.clientX - rect.left;
            const maxWidth = samContainer.current.offsetWidth;
            const percentMove = round(clickPos / maxWidth * 100);
            if (Math.abs(percentMove - currentPos) < 20) {
                return;
            }
            const facing = currentFrame.facing;
            moveSam(percentMove, facing);
        }
    }

    const moveSam = (percentMove, facing) => {
        setIsIdle(false);
        setCurrentPos(percentMove);
        setFrameQueue(prev => {
            return prev.concat(moveLogic(currentPos, percentMove, facing));
        })
    }

    const randomWalkGenerator = (currentPercent) => {
        var nextPercent = currentPercent;
        while (Math.abs(nextPercent - currentPercent) < 20) {
            nextPercent = Math.random(0, 80) * 100;
        }
        return nextPercent;
    }

    const findMouseAngle = (globalCoords) => {
        function getOffsetTop(element) {
            return element ? (element.offsetTop + getOffsetTop(element.offsetParent)) : 0;
        }
        function getOffsetLeft(element) {
            return element ? (element.offsetLeft + getOffsetLeft(element.offsetParent)) : 0;
        }  
        const manualOffset = {
            x: samRef.current.clientWidth * 0.16,
            y: samRef.current.clientHeight * 0.6
        }
        const samCoordsConst = {
            x: getOffsetLeft(samRef.current) + manualOffset.x,
            y: getOffsetTop(samRef.current) - window.scrollY + manualOffset.y
        }
        const pointFromOrigin = {
            x: (globalCoords.x - samCoordsConst.x),
            y: -(globalCoords.y - samCoordsConst.y)
        }
        const radians = Math.atan2(pointFromOrigin.x, pointFromOrigin.y);
        var pi = Math.PI;
        const angle = radians * (180 / pi);
        setLookDirectionAngle(angle);
    }

    useEffect(() => {
        // 👇️ get global mouse coordinates
        const handleWindowMouseMove = event => {
            findMouseAngle({
                x: event.screenX,
                y: event.screenY,
            });
        };
        window.addEventListener('mousemove', handleWindowMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleWindowMouseMove);
        };
    }, []);

    useEffect(() => {
        if (isIdle){
            var curFrameClone = structuredClone(currentFrame);
            curFrameClone['image'] = (curFrameClone['facing'] === 'left') ? 
                mouseAngleToImage(-lookDirectionAngle) :
                mouseAngleToImage(lookDirectionAngle);
            setCurrentFrame(curFrameClone);
        }
    }, [isIdle, lookDirectionAngle])
    useEffect(() => {

        const idleDecision = () => {
            const random = Math.random();
            if (random < 0.002) {
                //walk somewhere?
                const movePercent = randomWalkGenerator(currentPos);
                moveSam(movePercent, currentFrame.facing);
            }
            else if (random < 0.9) {
                //find mouse and move head
                //console.log('find mouse and move head decision');
                //const angle = findMouseAngle();
            }
            else {
                //do nothing?
                //console.log('do nothing decision');
            }
        }
        // play frame in frameQueue every 60ms
        const timer = setInterval(() => {
            if (isIdle) {
                idleDecision();
            }
            else {
                setFrameQueue(prev => {
                    if (prev.length) {
                        setCurrentFrame(prev[0]);
                        return prev.slice(1);
                    }
                    else {
                        setIsIdle(true);
                        return prev;
                    }
                })
            }
        }, 60);
        return () => clearInterval(timer);
    }, [isIdle, currentFrame.facing, currentPos]);

    useEffect(() => {
        //on mount, move sam
        const movePercent = randomWalkGenerator(currentPos);
        moveSam(movePercent, currentFrame.facing);
    }, [])

    return (
        <div className="sam-container" onClick={(e) => samClickHandler(e)} ref={samContainer}>
            <ImagesPlayer
                currentFrame={currentFrame}
                samRef={samRef}
                imageLoaded={imageLoaded}
            />
        </div>
    )
}

export default Sam
