import React, { useState } from 'react'

const DegreeSelector = ({ curDegree, degreeOptions, setDegreeOptions }) => {
    const degreeNumToWord = {
        "2": "seconds",
        "3": "thirds",
        "4": "fourths",
        "5": "fifths",
        "6": "sixths",
        "7": "sevenths"
    }
    const [defaultOptions, setDefaultOptions] = useState([
            {degree: 2, status: true},
            {degree: 3, status: true},
            {degree: 4, status: true},
            {degree: 5, status: true},
            {degree: 6, status: true},
            {degree: 7, status: true}
    ]);

    const toggleOption = (degree) => {
        let optionsClone = JSON.parse(JSON.stringify(defaultOptions));
        let enabledOptions = [];
        for (let option of optionsClone){
            if (option.degree === degree){
                option.status = !option.status;
            }
            if (option.status === true){
                enabledOptions.push(option.degree);
            }
        }
        if (enabledOptions.length){
            setDegreeOptions(enabledOptions);
            setDefaultOptions(optionsClone);
        }
    }
    return (
        <span className="bold degree-selector-parent">
            <span className="current-text">{curDegree ? degreeNumToWord[curDegree] : ''}</span>
            <div className="degree-selector-container">
                <div className="title">Enabled Degrees</div>
                <div className="options-container">
                    {defaultOptions.map((option) => {
                        return(
                        <div 
                            key={option.degree}
                            className={`option ${option.status}`}
                            onClick={() => toggleOption(option.degree)}
                        >
                            {option.degree}
                        </div>
                        )
                    })}
                </div>
            </div>
        </span>
    )
}

export default DegreeSelector
