const scaleIntervals = {
    'Major': [2, 2, 1, 2, 2, 2, 1],
    'Minor': [2, 1, 2, 2, 1, 2, 2],
    'Natural Minor': [2, 1, 2, 2, 2, 2, 1],
    'Harmonic Minor': [2, 1, 2, 2, 1, 3, 1],
    'Dorian': [2, 1, 2, 2, 2, 1, 2],
    'Phrygian': [1, 2, 2, 2, 1, 2, 2],
    'Lydian': [2, 2, 2, 1, 2, 2, 1],
    'Mixolydian': [2, 2, 1, 2, 2, 1, 2],
    'Aeolian': [2, 1, 2, 2, 1, 2, 2],
    'Locrian': [1, 2, 2, 1, 2, 2, 2]
}
const naturalNoteList = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
var noteToNumDict = {
    'C': 1,
    'D': 3,
    'E': 5,
    'F': 6,
    'G': 8,
    'A': 10,
    'B': 12,
}
const sharpsNoteList = ['A', 'A♯', 'B', 'C', 'C♯', 'D', 'D♯', 'E', 'F', 'F♯', 'G', 'G♯'];
const flatsNoteList = ['A', 'B♭', 'B', 'C', 'D♭', 'D', 'E♭', 'E', 'F', 'G♭', 'G', 'A♭'];

export function numToNote(num, baseNote) {
    const baseNoteNum = noteToNumDict[baseNote]; 
    const outputNumBaseValue = num % 12; 
    var octaveValue = (num - outputNumBaseValue) / 12;
    var accidentalDiff = outputNumBaseValue - baseNoteNum;
    // Check if base note one octave higher results in less accidentals, to prevent C♯♯♯♯♯♯♯♯0 vs C♭♭1
    if (Math.abs(outputNumBaseValue - (baseNoteNum + 12)) < Math.abs(accidentalDiff)){
        octaveValue++;
        accidentalDiff = outputNumBaseValue - (baseNoteNum + 12);
    }
    else if ((Math.abs((outputNumBaseValue + 12) - baseNoteNum)) < Math.abs(accidentalDiff)){
        octaveValue--;
        accidentalDiff = (outputNumBaseValue + 12) - baseNoteNum;
    }
    var accidentals = '';
    if (accidentalDiff < 0){
        for (let i = 0; i < -accidentalDiff; i++){
            accidentals += '♭';
        }
    }
    else if (accidentalDiff > 0){
        for (let i = 0; i < accidentalDiff; i++){
            accidentals += '♯';
        }
    }
    return baseNote + accidentals + octaveValue;
}

export function noteToNum(note) {
    const octave = note.slice(-1);
    const baseNote = note.slice(0, 1);
    const accidentals = note.slice(1, -1);
    const sharps = (accidentals.match(/♯/g)||[]).length;
    const flats = (accidentals.match(/♭/g)||[]).length;
    return noteToNumDict[baseNote] + sharps - flats + (octave * 12);
}

function incrementIndex(currentIndex, arrayLength, increment) {
    if (increment === 1) {
        return (currentIndex + 1 >= arrayLength) ?
        0 : currentIndex + increment;
    }
    else {
        return (currentIndex - 1 < 0) ?
        arrayLength - 1 : currentIndex + increment;
    }
}

export function generateScale(root, type, degree = 0, start = null, end = null) {
    const endToggle = end ? true : false;
    // Default start note is scale root
    start = (start === null) ? noteToNum(root) : noteToNum(start);
    // Default end note is just one octave up
    end = (end === null) ? noteToNum(root) + 12 : noteToNum(end);
    const increment = end > start ? 1 : -1;
    // Find index for which base note to start on.
    var baseNote = root.slice(0, 1);
    let noteBaseIndex = 0;
    while (baseNote !== naturalNoteList[noteBaseIndex]) {
        noteBaseIndex = incrementIndex(noteBaseIndex, naturalNoteList.length, increment);
    }
    const scaleInterval = scaleIntervals[type];
    var startToggle = false;
    var currentNoteNum = increment === 1 ? noteToNum(root) : noteToNum(root) + (12 * 10) ;
    var scaleIntervalIndex = increment === 1 ? 0 : scaleInterval.length - 1;
    var scaleNotes = [];
    // for (let i = 0; i < 30; i++){
    while (currentNoteNum !== end) {
        if (currentNoteNum === start) {
            startToggle = true;
        }
        if (startToggle) {
            scaleNotes.push(numToNote(currentNoteNum, naturalNoteList[noteBaseIndex]));
            // If scale needs to generated in thirds, fifths, etc..
            if (degree && degree != 2) {
                var degreeNoteNum = currentNoteNum;
                var degreeScaleIntervalIndex = scaleIntervalIndex;
                var degreeNoteBaseIndex = noteBaseIndex;
                for (let i = 0; i < degree - 1; i++) {
                    degreeNoteNum = increment === 1 ? 
                        degreeNoteNum + scaleInterval[degreeScaleIntervalIndex] :
                        degreeNoteNum - scaleInterval[degreeScaleIntervalIndex];
                    degreeScaleIntervalIndex = incrementIndex(degreeScaleIntervalIndex, scaleInterval.length, increment);
                    degreeNoteBaseIndex = incrementIndex(degreeNoteBaseIndex, naturalNoteList.length, increment);
                }
                scaleNotes.push(numToNote(degreeNoteNum, naturalNoteList[degreeNoteBaseIndex]));
            }
        }
        currentNoteNum = increment === 1 ? 
            currentNoteNum + scaleInterval[scaleIntervalIndex] :
            currentNoteNum - scaleInterval[scaleIntervalIndex];
        scaleIntervalIndex = incrementIndex(scaleIntervalIndex, scaleInterval.length, increment);
        noteBaseIndex = incrementIndex(noteBaseIndex, naturalNoteList.length, increment);
    }
    if (endToggle) { scaleNotes.push(numToNote(end, naturalNoteList[noteBaseIndex])); }
    return scaleNotes;
}

export function generateKeyConstants(scale) {
    for (const note of scale) {
        if (note.includes('♭')) {
            return {
                "scaleNoteList": scale,
                "fullNoteList": flatsNoteList,
                "sharpsOrFlats": '♭'
            }
        }
    }
    return {
        "scaleNoteList": scale,
        "fullNoteList": sharpsNoteList,
        "sharpsOrFlats": '♯'
    }
}

export function singleNoteFlatSharpCheck(note, sharpsOrFlats) {
    if (note) {
        const octave = note.slice(-1);
        const trimmedNote = note.slice(0, -1);
        if ((note.includes('♯')) && (sharpsOrFlats === '♭')) {
            return flatsNoteList[sharpsNoteList.indexOf(trimmedNote)] + octave;
        }
        else if ((note.includes('♭')) && (sharpsOrFlats === '♯')) {
            return sharpsNoteList[flatsNoteList.indexOf(trimmedNote)] + octave;
        }
        else {
            return note;
        }
    }
    else {
        return null;
    }
}

function randNoteInScale(scaleNotes) {
    var randScaleNote = scaleNotes[Math.floor(Math.random()*scaleNotes.length)];
    var randOctave = Math.floor(Math.random() * (6 - 2 + 1) + 2);
    var outputNote = randScaleNote.slice(0, -1) + randOctave;
    while ((noteToNum(outputNote) > noteToNum('C♯6')) || 
        (noteToNum(outputNote) < noteToNum('E2'))) {
        randScaleNote = scaleNotes[Math.floor(Math.random()*scaleNotes.length)];
        randOctave = Math.floor(Math.random() * (6 - 2 + 1) + 2);
        outputNote = randScaleNote.slice(0, -1) + randOctave;
    }
    return outputNote;
}

function getNthDegNote(scaleNotes, curNote, degree) {
    if (degree > 0) {
        degree--;
    }
    else if (degree < 0) {
        degree++;
    }
    var octave = curNote.slice(-1);
    const noOctaveCurNote = curNote.slice(0, -1);
    let noOctaveScaleNotes = scaleNotes.map((note) => {
        return note.slice(0, -1);
    })
    var curIndex = noOctaveScaleNotes.indexOf(noOctaveCurNote);
    var degIndex = curIndex + degree;
    if (degIndex > scaleNotes.length - 1) {
        octave++;
        degIndex -= 7;
    }
    else if (degIndex < 0) {
        octave--;
        degIndex += 7;
    }
    return noOctaveScaleNotes[degIndex] + octave;
}

export function generateNextNoteSeq(scaleNotes, scaleType, curNote = null, degree = null) {
    if (!curNote){
        curNote = randNoteInScale(scaleNotes);
    }
    var nextGoalNote = randNoteInScale(scaleNotes);
    if (!degree) {
        degree = Math.floor(Math.random() * (7 - 2 + 1) + 2);
    }
    // check if degree of note is outside guitar bounds
    // generate new random if out of bounds
    let exceedBoundToggle = false;
    while (exceedBoundToggle === false) {
        exceedBoundToggle = true;
        if (curNote === nextGoalNote){
            exceedBoundToggle = false;
            nextGoalNote = randNoteInScale(scaleNotes);
            continue;
        }
        if (
            noteToNum(getNthDegNote(scaleNotes, curNote, -degree)) < noteToNum('E2') ||
            noteToNum(getNthDegNote(scaleNotes, curNote, degree)) > noteToNum('C♯6')
        ) {
            exceedBoundToggle = false;
            curNote = randNoteInScale(scaleNotes);
        }
        if (
            noteToNum(getNthDegNote(scaleNotes, nextGoalNote, -degree)) < noteToNum('E2') ||
            noteToNum(getNthDegNote(scaleNotes, nextGoalNote, degree)) > noteToNum('C♯6')
        ) {
            exceedBoundToggle = false;
            nextGoalNote = randNoteInScale(scaleNotes);
        }
    }
    // Trim tail of note seq, since you want to END when reach goal note
    // not end when goal note is base, then go degree up again
    // Ex: 2nd degree, C Major, Start C end F
    // CD, DE, EF, FG. This should end at CD, DE, EF
    const noteSeq = generateScale(scaleNotes[0], scaleType, degree, curNote, nextGoalNote);
    for (let i = 0; i < noteSeq.length; i++){
        if (noteSeq[i] === nextGoalNote){
            noteSeq.length = i + 1;
            break;
        }
    }
    return {
        "noteSeq": noteSeq,
        "startNote": curNote,
        "endNote": nextGoalNote
    }
}

export function checkInScale(note, scale) {
    if (note && scale) {
        const trimmedNote = note.slice(0, -1);
        for (const scaleNote of scale) {
            const trimmedScaleNote = scaleNote.slice(0, -1);
            if (trimmedNote === trimmedScaleNote) {
                return true;
            }
        }
    }
    return false;
}