import React, { useState, useEffect } from "react";
import endFlag from '../../assets/flag no bottom.png'
import startFlag from '../../assets/start flag no bottom.png'
const FretboardNote = ({ notePlayedHandler, note, correctToggle, currentlyPlaying, endNoteToggle, nextNoteToggle, startNoteToggle }) => {
    const [activeClass, setActiveClass] = useState('');
    const [correctStatus, setCorrectStatus] = useState('false');
    const notePlayedFeedback = (note, setActiveClass) => {
        setActiveClass(notePlayedHandler(note) ? "current" : "");
        setTimeout(() => setActiveClass(''), 1000);
    }

    useEffect(() => {
        // delay updating correctStatus so when you get the right note
        // it doesn't immediately mark it as wrong right after since sequence updates
        if (correctToggle === false && correctStatus === true) {
            setTimeout(() => {
                setCorrectStatus(false);
            }, 1000)
        }
        else {
            setCorrectStatus(correctToggle);
        }
    }, [correctToggle])

    const [endFlagToggle, setEndFlagToggle] = useState(false);
    const endFlagDefaultStyle = {
        left: "4px",
        transform: "rotate(0deg)",
        opacity: "0%"
    }
    const [endFlagStyle, setEndFlagStyle] = useState(endFlagDefaultStyle)
    useEffect(() => {
        if (endNoteToggle) {
            setEndFlagStyle({
                left: "6px",
                transform: "rotate(0deg)",
                opacity: "100%"
            })
            setEndFlagToggle('true');
        }
        else {
            setEndFlagToggle((prev) => {
                if (prev === 'true') {
                    const random = (Math.random() - 0.5) * 150;
                    const spinRandom = (Math.random() - 0.5) * 180;
                    setEndFlagStyle({
                        left: random + "px",
                        transform: `rotate(${spinRandom}deg)`,
                        transition: "1s"
                    })
                    setTimeout(() => {
                        setEndFlagStyle(endFlagDefaultStyle);
                        setEndFlagToggle('false');
                    }, 500)
                    return 'exit-animation';
                }
                else {
                    return 'false';
                }
            })
        }
    }, [endNoteToggle])
    return (
        <div
            className="note-container"
            onClick={() => { notePlayedFeedback(note, setActiveClass) }}
        >
            <div className={
                `note ${activeClass}
                ${currentlyPlaying ? "current" : ""} 
                ${correctStatus ? "" : "incorrect-toggle"}
                ${endNoteToggle ? "end-toggle" : ""}
                ${startNoteToggle ? "start-toggle" : ""}
                ${nextNoteToggle ? "next-toggle" : ""}`
            }>
                {note.slice(0, -1)}
            </div>
            <img style={endFlagStyle} className={`flag end-flag ${endFlagToggle}`} src={endFlag} draggable="false"/>
            <img className={`flag start-flag ${startNoteToggle}`} src={startFlag} draggable="false"/>
        </div>
    );
};

export default FretboardNote;

