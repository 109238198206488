import React, { useState, useEffect, useCallback } from "react";
import FretboardNote from './fretboardNote'
import { noteToNum } from '../helpers'
import '../../css/fretboard.scss'

const Fretboard = ({ noteList, currentNote, startNote, endNote, nextNote, notePlayedHandler }) => {
    const defaultStringTuning = ['E4', 'B3', 'G3', 'D3', 'A2', 'E2'];
    const [endNoteUnique, setEndNoteUnique] = useState({});
    const [startNoteUnique, setStartNoteUnique] = useState({});

    const generateStringNotes = useCallback((noteList) => {
        var output = [];
        var uniqueIdentifier = 0;
        for (var currentString of defaultStringTuning) {
            const stringNote = Array.from(currentString)[0];
            var stringOctave = parseInt(Array.from(currentString)[1]);
            var index = noteList.indexOf(stringNote);
            var stringNotes = [];
            // Generate notes for one string
            for (let i = 0; i <= 21; i++) {
                // First check if need to increment octave up
                const prevNote = stringNotes.slice(-1)[0];
                if (prevNote) {
                    if (Array.from(prevNote.note)[0] === 'B'
                        && Array.from(prevNote.note)[1] != '♭') {  
                        stringOctave = stringOctave + 1;
                    }
                }
                // Add note and increment counter
                stringNotes.push({
                    note: noteList[index] + stringOctave,
                    id: uniqueIdentifier
                });
                uniqueIdentifier++;
                index = ((index + 1) > (noteList.length - 1)) ? 0 : index + 1;
            }
            // transpose for display since needs to be organized by col not row
            output.push(stringNotes);
        }
        output = output[0].map((_, colIndex) => output.map(row => row[colIndex]));
        return output;
    }, []);
    let stringArray = generateStringNotes(noteList);

    const getRandomUniqueNote = (note, stringArray) => {
        // Solves the issue of multiple spots to play one note on guitar
        // Picks one at random so start/end notes are unique
        if (!note) return;
        let idList = [];
        for (var i = 0; i < stringArray.length; i++) {
            var string = stringArray[i];
            for (var j = 0; j < string.length; j++) {
                if (noteToNum(string[j].note) === noteToNum(note)) {
                    idList.push(string[j]);
                }
            }
        }
        return idList[Math.floor(Math.random() * idList.length)];
    }

    useEffect(() => {
        if (startNote === endNoteUnique.note){
            setStartNoteUnique(endNoteUnique);
        }
        else {
            setStartNoteUnique(getRandomUniqueNote(startNote, stringArray));
        }
        setEndNoteUnique(getRandomUniqueNote(endNote, stringArray));
    }, [startNote, endNote]);


    useEffect(() => {
        if (currentNote === startNoteUnique.note){
            setStartNoteUnique({})
        }
    }, [currentNote])
    return (
        <div className="fretboard-container">
            {stringArray.map((stringArray, i) => {
                return (
                    <div
                        className="col"
                        key={i}
                        style={{
                            width: (6.18 * (34 / (i - -19.8)) - 2.6) + '%',
                            height: (11 + (6 / 23) * i) * 6 + '%'
                        }}
                    >
                        {stringArray.map((note, i) => {
                            return (
                                <div
                                    className="row"
                                    key={note.id}
                                >
                                    <FretboardNote
                                        notePlayedHandler={notePlayedHandler}
                                        note={note.note}
                                        currentlyPlaying={currentNote === note.note ? true : false}
                                        correctToggle={currentNote === nextNote}
                                        endNoteToggle={note.id === endNoteUnique.id}
                                        startNoteToggle={note.id === startNoteUnique.id}
                                        nextNoteToggle={note.note === nextNote}
                                    />
                                </div>
                            )
                        }
                        )}
                    </div>
                )
            }
            )}
        </div>
    )
};

export default Fretboard;
