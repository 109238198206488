import React, { useState, useEffect } from 'react'

const SmallScreenOverlay = () => {

    const [width, setWidth] = useState(window.innerWidth);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);
    return (
        <>
            {width > 1000 ?
                <></>
                :
                <div className="small-screen-overlay">
                    <div className="content-container">
                        <div className="text">
                            I don't know how to display this for smaller screens sorry.
                        </div>
                        <div className="text">
                            {width}px / 1000px width required
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default SmallScreenOverlay
