import React from 'react'

const IndividualSkill = ({name, image}) => {
  return (
    <div className="individual-skill-container">
        <img src={image} alt={`${name} logo`}/>
        <div className="skill-name">{ name }</div>
    </div>
  )
}

export default IndividualSkill