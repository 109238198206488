import MicrophoneStream from "microphone-stream";
import getUserMedia from 'getusermedia'
import freqToNote from '../constants'
const Pitchfinder = require("pitchfinder");

function pitchToNote(pitch) {
    const errorMargin = 0.02;
    let returnValue;
    Object.entries(freqToNote).forEach(([note, frequencyList]) => {
        let index = 0;
        for (const freq of frequencyList) {
            const upperMargin = freq * (1 + errorMargin);
            const lowerMargin = freq * (1 - errorMargin);
            if ((pitch < upperMargin) && (pitch > lowerMargin)) {
                returnValue = note + index;
            }
            index++;
        }
    })
    return returnValue;
}

export function initPitchFinder(setNote, setMicPermissions) {
    /**
     * Initializes microphone stream, calculates note and sets it using setNote
     * 
     * @param setNote update note state
     */
    navigator.mediaDevices.getUserMedia({ audio: true })
        .then(function (stream) {
            setMicPermissions(true);
            let micStream;
            getUserMedia({ video: false, audio: true }, (err, stream) => {
                const detectPitch = Pitchfinder.AMDF({ sampleRate: 4000 /*48000*/ });
                if (err) {
                    console.log(err);
                }
                else {
                    micStream = new MicrophoneStream();
                    micStream.setStream(stream);
        
                    micStream.on('data', function (chunk) {
                        const raw = MicrophoneStream.toRaw(chunk)
                        const pitch = detectPitch(raw); // null if pitch cannot be identified
                        // clean up this code idiot
                        if ((pitch !== null) && (pitch < 1500)) {
                            const note = pitchToNote(pitch);
                            if (note !== null) {
                                setNote(note);
                            }
                        }
                    })
                    return micStream
                }
            })
        })
        .catch(function (err) {
            setMicPermissions(false);
            alert('ok i guess : (');
        });
}
