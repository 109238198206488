const maxPercent = 75;
//const timeToWalkFull = 2.6;
const widthToPercent = (width) => {
    return `${width * maxPercent / 100}%`;
}
export const round = (value, precision) => {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}
const fullWalkCycle = [
    'walk0001',
    'walk0002',
    'walk0003',
    'walk0004',
    'walk0005',
    'walk0006',
    'walk0007',
    'walk0008',
    'walk0009',
    'walk0010',
    'walk0011',
    'walk0012',
    'walk0013',
    'walk0014',
    'walk0015',
    'walk0016',
    'walk0017',
    'walk0018',
    'walk0019'
];
const fullTurnCycle = [
    'turn0001',
    'turn0002',
    'turn0003',
    'turn0004',
    'turn0005',
    'turn0006',
    'turn0007',
    'turn0008',
    'turn0009',
    'turn0010',
    'turn0011',
    'turn0012',
    'turn0013',
    'turn0014',
    'turn0015',
    'turn0016',
    'turn0017',
    'turn0018',
    'turn0019',
    'turn0020',
    'turn0021',
    'turn0022',
    'turn0023',
    'turn0024',
    'turn0025',
];
const fullWalkToSitCycle = [
    'walkToSit0001',
    'walkToSit0002',
    'walkToSit0003',
    'walkToSit0004',
    'walkToSit0005',
    'walkToSit0006',
    'walkToSit0007',
    'walkToSit0008',
    'walkToSit0009',
    'walkToSit0010',
    'walkToSit0011',
    'walkToSit0012',
    'walkToSit0013',
    'walkToSit0014',
    'walkToSit0015',
    'walkToSit0016',
];
const fullSitToWalkCycle = [
    'sitToWalk0001',
    'sitToWalk0002',
    'sitToWalk0003',
    'sitToWalk0004',
    'sitToWalk0005',
    'sitToWalk0006',
    'sitToWalk0007',
    'sitToWalk0008',
    'sitToWalk0009',
    'sitToWalk0010',
    'sitToWalk0011',
    'sitToWalk0012',
    'sitToWalk0013',
    'sitToWalk0014',
]

export const mouseAngleToImage = (angle) => {
    if (angle >= -11.25 && angle <= 11.25){
        return 'lookDirection0';
    } 
    else if (angle >= 11.25 && angle <=  33.75) {
        return 'lookDirection1';
    }
    else if (angle >= 33.75 && angle <=  56.25) {
        return 'lookDirection2';
    }
    else if (angle >= 56.25 && angle <=  78.75) {
        return 'lookDirection3';
    }
    else if (angle >= 78.75 && angle <=  101.25) {
        return 'lookDirection4';
    }
    else if (angle >= 101.25 && angle <=  123.75) {
        return 'lookDirection5';
    }
    else if (angle >= 123.75 && angle <= 146.25) {
        return 'lookDirection6';
    }
    else if (angle >= 146.25 && angle <= 168.75) {
        return 'lookDirection7';
    }
    else if (angle >= 168.75 || angle <= -168.75){
        return 'lookDirection8';
    }
    else if (angle >= -168.75 && angle <= -146.25){
        return 'lookDirection9';
    }
    else if (angle >= -146.25 && angle <= -123.75){
        return 'lookDirection10';
    }
    else if (angle >= -123.75 && angle <= -101.25){
        return 'lookDirection11';
    }
    else if (angle >= -101.25 && angle <= -78.75){
        return 'lookDirection12';
    }
    else if (angle >= -78.75 && angle <= -56.25){
        return 'lookDirection13';
    }
    else if (angle >= -56.25 && angle <= -33.75){
        return 'lookDirection14';
    }
    else if (angle >= -33.75 && angle <= -11.25){
        return 'lookDirection15';
    }
}
export const moveLogic = (initialPos, endPos, facing) => {
    // main function called by sam.js
    var frameQueue = [];
    const directionToMove = ((endPos - initialPos) < 0?'left':'right');
    const walkOffset = (directionToMove === 'left') ? 13 : -13;
    if (directionToMove === facing){
        frameQueue = frameQueue.concat(sitToWalk(initialPos, facing));
        frameQueue = frameQueue.concat(walkTo(initialPos - walkOffset, endPos));
        frameQueue = frameQueue.concat(walkToSit(facing, endPos));
    }
    else{
        const turnOffset = (facing === 'left') ? 16 : -16;
        const sitFacing = (facing === 'left'?'right':'left');
        frameQueue = frameQueue.concat(sitToWalk(initialPos, facing));
        frameQueue = frameQueue.concat(turn(facing, initialPos + walkOffset));
        frameQueue = frameQueue.concat(walkTo(initialPos + walkOffset + turnOffset, endPos));
        frameQueue = frameQueue.concat(walkToSit(sitFacing, endPos));
    }
    return frameQueue;
}
const turn = (facing, initialPos) => {
    //turn animation has like 10 frames where sam has to move a bit
    var counter = 0;
    var startToMoveCounter = 0;
    var frameQueue = [];
    for (var image of fullTurnCycle){
        let pos = initialPos;
        if (counter > 14){
            pos = initialPos + startToMoveCounter;
            if (facing === 'left'){
                startToMoveCounter += 2;
            }
            else{
                startToMoveCounter -= 2;
            }
        }
        frameQueue.push({
            image: image,
            style: {
                left: widthToPercent(pos)
            },
            facing: facing
        });
        counter ++;
    }
    return frameQueue
}
const walkToSit = (facing, endPos) => {
    var frameQueue = [];
    for (var image of fullWalkToSitCycle){
        frameQueue.push({
            image: image,
            style: {
                left: widthToPercent(endPos)
            },
            facing: facing
        })
    }
    return frameQueue;
}
const sitToWalk = (initialPos, facing) => {
    var counter = 0;
    var startToMoveCounter = 0;
    var frameQueue = [];
    for (var image of fullSitToWalkCycle){
        let pos = initialPos;
        if (counter > 6){
            pos = initialPos + startToMoveCounter;
            if (facing === 'left'){
                startToMoveCounter -= 2;
            }
            else{
                startToMoveCounter += 2;
            }
        }
        frameQueue.push({
            image: image,
            style: {
                left: widthToPercent(pos)
            },
            facing: facing
        });
        counter ++;
    }
    return frameQueue
}
const getLeftValue = (numFrames, initialPos, endPos, counter) => {
    const percentageProgress = counter/numFrames;
    const distanceWalk = endPos - initialPos;
    const widthWalk = (distanceWalk < 0) ?
        initialPos - (Math.abs(distanceWalk) * percentageProgress)
        :
        initialPos + (distanceWalk * percentageProgress);
    return widthToPercent(widthWalk);
}
const getWalkFrames = (numFrames, initialPos, endPos) => {
    const walkCycleFrames = fullWalkCycle.length;
    var counter = 1;
    var walkCycleCounter = 0;
    var imageQueue = [];
    const leftRight = ((endPos - initialPos) < 0 ? 'left' : 'right')
    while (counter < numFrames) {
        if (walkCycleCounter === walkCycleFrames) {
            walkCycleCounter = 0;
        }
        const nextFrame = {
            image: fullWalkCycle[walkCycleCounter],
            style: {
                left: getLeftValue(numFrames, initialPos, endPos, counter)
            },
            facing: leftRight
        }
        imageQueue.push(nextFrame);
        walkCycleCounter++;
        counter++;
    }
    return imageQueue;
}
export const walkTo = (initialPos, endPos) => {
    const framesToWalkFull = 57;
    const percentToWalk = endPos - initialPos;
    const requiredFrames = Math.abs(round((percentToWalk / 100) * framesToWalkFull));
    const frameQueue = getWalkFrames(requiredFrames, initialPos, endPos);
    return frameQueue;
}