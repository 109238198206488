import React, { useEffect, useState } from 'react'
import Buildings from '../assets/background/back buildings no sun state2.png'
const BackBuildingsLight = () => {
    const [windowStates, setWindowStates] = useState([
        false, true, true
    ])
    useEffect(() => {
        const timer = setInterval(() => {
            setWindowStates(prev => {
                var output = [];
                for (var state of prev) {
                    const outputState = (Math.random() < 0.1) ? !state : state;
                    output.push(outputState);
                }
                return output;
            })
        }, 10000);
        return () => clearInterval(timer);
    }, []);
    return (
        <>
            <img className={`back-buildings window7 windowToggle ${windowStates[0] === true ? 'active' : 'inactive'}`} src={Buildings} alt='' />
            <img className={`back-buildings window8 windowToggle ${windowStates[1] === true ? 'active' : 'inactive'}`} src={Buildings} alt='' />
            <img className={`back-buildings window9 windowToggle ${windowStates[2] === true ? 'active' : 'inactive'}`} src={Buildings} alt='' />
        </>
    )
}

export default BackBuildingsLight
