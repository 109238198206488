import React, {useState} from 'react'
import Sam from '../components/sam'
import Background from './background'
import '../css/frame.scss'
const Frame = () => {
    const imageCount = 96;
    const [loadedImages, setLoadedImages] = useState(0);
    const [progressWidth, setProgressWidth] = useState(0);
    const imageLoaded = () => {
        setLoadedImages((prev) => {
            return prev + 1;
        });
        setProgressWidth((loadedImages/imageCount) * 100);
    }
    return (
        <div className={`frame-container ${loadedImages === imageCount ? 'loaded' : 'loading'}`}>
            <div className={`loading-bar ${loadedImages === imageCount ? 'loaded' : 'loading'}`}>
                <div style={{width: progressWidth + '%'}} className="progress"/>
            </div>
            <Background imageLoaded={imageLoaded}/>
            <Sam imageLoaded={imageLoaded}/>
        </div>
    )
}

export default Frame
